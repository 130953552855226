<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>IntelectuGal</ion-title>
        <logout-button />
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-item-divider>
        <ion-label> Materiais </ion-label>
      </ion-item-divider>

      <ion-item button @click="$router.push({name: 'practice'})" detail>
          <ion-note color="success" slot="end">{{language.total_score}}</ion-note>
        <ion-label> {{language.name}} </ion-label>
      </ion-item>
    </ion-content>
  </ion-page>
</template>

<script>
import LogoutButton from "@/components/LogoutButton.vue";

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonNote,
} from "@ionic/vue";

export default {
  name: "Materials",
  components: {
    LogoutButton,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonNote
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("user/getMaterials", this.user).then(
      (materials) => {
        console.log(materials);
      },
      (error) => {
        console.log(error);
      }
    );
  },
  computed: {
    language() {
      return this.$store.state.user.language;
    },
  },
};
</script>

