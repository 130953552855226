<template>
  <ion-buttons slot="end">
    <ion-button @click="logOut"> <ion-icon :icon="exitIcon" /></ion-button>
  </ion-buttons>
</template>

<script>
import {
  IonIcon,
  IonButton,
} from "@ionic/vue";

import { exitOutline } from "ionicons/icons";

export default {
  name: "LogoutButton",
  components: {
    IonIcon,
    IonButton,
  },
  data() {
    return {
      exitIcon: exitOutline,
    };
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/identificarse");
    },
  },
};
</script>

